@use "sass:math";
.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	width: 100%;

	.message {
		font-family: Roboto, sans-serif;
		margin: 0 1em;
		opacity: 0.5;
		text-align: center;
	}
	.icon {
		color: inherit;
		height: 9em;
		width: 9em;
	}
}
