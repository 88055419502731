@use "sass:math";
@use '~@THC/styles/core';

.container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: core.$space * 2 core.$space * 3;
	border-bottom: 1px solid rgba(0, 0, 0, 0.10);
}
