@use "sass:math";
@use '~@THC/styles/color';
@use '~@THC/styles/core';

.navButton {
	&:global(.MuiButton-root) {
		& + a {
			margin-left: core.$space * 2;
		}
		&:hover {
			border-color: color.$primary-black;
			background: inherit;
		}
		font-size: 16px;
		border-bottom: solid 2px transparent;
		border-radius: 0;
		min-width: auto;
		padding: 8px 0;
		height: 24px;
		font-weight: normal;
		transition: border-color 150ms ease-in-out;
		white-space: nowrap;
	}
}

.navButtonActive {
	&:global(.MuiButton-root) {
		font-weight: bold;
	}
}
