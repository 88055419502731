@use "sass:math";
@use '~@THC/styles/color';
@use '~@THC/styles/modifiers';

.row {
	position: relative;

	:global(.MuiTableCell-root) {
		border-bottom: 2px solid color.$light-grey-3;
	}

	&.expanded {
		:global(.MuiTableCell-root) {
			border-bottom: none;
		}
	}

	.cell {
		&:global(.MuiTableCell-root) {
			padding: 16px 12px;
			vertical-align: middle;
			overflow: hidden;
			font-size: inherit;
			max-width: 304px;
			word-break: break-word;
		}
	}

	.expandTrigger {
		width: 0;
		padding: 0;
		margin: 0;
		overflow: hidden;

		& button {
			position: absolute;
			border: none;
			background: none;
			cursor: pointer;
			height: 26px;
			top: calc(50% - 13px);
			left: -42px;
		}

		& .expandIcon {
			border: 2px solid color.$light-grey-2;
			border-radius: 50%;
			padding: 3px;
		}
	}
}

.slim {
	:global(.MuiTableCell-root) {
		border-bottom: 1px solid #{modifiers.darkShade(color.$primary-white, 10%)};
	}

	.cell {
		&:global(.MuiTableCell-root):first-child {
			padding-left: 16px;
		}

		&:global(.MuiTableCell-root) {
			padding: 8px 8px;
		}
	}
}

.infoRow {
	:global(.MuiTableCell-root) {
		border-bottom: 3px solid color.$light-grey-3;
	}

	.infoCell {
		height: fit-content !important;
		padding: 0 !important;

		.infoWrapper {
			position: relative;
			border-radius: 4px;
			background-color: color.$light-grey-3;
			padding: 24px;
			margin: 10px 0 16px;
			width: 100%;

			&:before {
				content: '';
				border-bottom: 8px solid color.$light-grey-3;
				border-left: 9px solid transparent;
				border-right: 9px solid transparent;
				height: 0;
				left: 0;
				right: 0;
				margin: auto;
				position: absolute;
				top: -8px;
				width: 0;
			}

			&:after {
				position: absolute;
				height: 2px;
				content: '';
				display: block;
				width: 100%;
				left: 0;
				bottom: -16px;
			}
		}
	}
}

.slimInfoRow {
	.infoCell {
		height: fit-content !important;
		padding: 0 !important;

		.infoWrapper {
			position: relative;
			padding: 0 16px 8px 16px;
			width: 100%;
		}
	}
}

.noRowsCell {
	border-bottom: 2px solid color.$light-grey-3;
	height: 100px;

	&:global(.MuiTableCell-root) {
		color: color.$mid-grey;
	}
}
