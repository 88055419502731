@use "sass:math";
@use '~@THC/styles/color';

.button {
	&:global(.MuiButtonBase-root) {
		& > span {
			width: 100%;
		}
		&:hover {
			background-color: inherit;
		}
		background-color: inherit;
		border: none;
		color: color.$primary-black;
		font-size: 16px;
		font-weight: normal;
		height: auto;
		justify-content: flex-start;
		line-height: 1.5;
		padding-left: 0;
		text-decoration: underline;
		width: auto;
		padding-top: 4px;
	}
}
