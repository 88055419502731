@use "sass:math";
@use '~@THC/styles/color';
@use '~@THC/styles/modifiers';

.slim{
	--border-radius: 8px;

	table {
		border-collapse: separate;
		border-spacing: 0;
	}

	table tr th,
	table tr td {
		border-bottom: 1px solid #{modifiers.darkShade(color.$primary-white, 10%)};
	}

	table tr th:last-child,
	table tr td:last-child {
		border-right: 1px solid #{modifiers.darkShade(color.$primary-white, 10%)};
	}

	table tr th:first-child,
	table tr td:first-child {
		border-left: 1px solid #{modifiers.darkShade(color.$primary-white, 10%)};
	}

	table tr th {
		background: modifiers.darkShade(color.$primary-white, 2.5%);
		border-top: solid 1px #{modifiers.darkShade(color.$primary-white, 10%)};
	}

	/* top-left border-radius */
	table tr:first-child th:first-child {
		border-top-left-radius: var(--border-radius);
	}

	/* top-right border-radius */
	table tr:first-child th:last-child {
		border-top-right-radius: var(--border-radius);
	}

	/* bottom-left border-radius */
	table tr:last-child td:first-child {
		border-bottom-left-radius: var(--border-radius);
	}

	/* bottom-right border-radius */
	table tr:last-child td:last-child {
		border-bottom-right-radius: var(--border-radius);
	}

	p {
		margin: 0;
	}
}
