@use "sass:math";
.root {
	background: none;
	border: none;
	cursor: pointer;
	display: flex;
	margin-left: 40px;
	padding: 0;
	align-items: center;
}

.rootModal {
	& > :global(.MuiIconButton-root) {
		top: 8px;
		right: 8px;
		padding: 12px;
		svg {
			width: 32px;
			height: 32px;
		}
	}
	&:global(.MuiPaper-root) {
		border-radius: 0;
		margin: 0;
		min-height: 100vh;
		position: absolute;
		right: 0;
		top: 0;
		width: 456px;
		padding: 48px;
	}
}

.scrollBody {
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.avatarName {
	font-size: 16px;
	padding: 8px 0 8px 16px;
}
