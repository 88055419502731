@use "sass:math";
@use '~@THC/styles/color';
@use '~@THC/styles/modifiers';

.tableRowHeader {
	border-bottom: 2px solid color.$light-grey-3;
	border-top: 2px solid color.$light-grey-3;

	:global(.MuiTableSortLabel-icon) {
		fill: color.$primary-black;
	}

	.expandTrigger {
		width: 0;
		padding: 0;
		margin: 0;
	}

	.sortLabel {
		line-height: 2rem;
	}

	& th {
		padding: 0 12px;
		height: 40px;
		font-weight: bold;
		font-size: 1rem;
	}
}

.slim {
	& th:first-child{
		padding-left: 16px;
	}

	& th {
		height: 32px;
		padding: 0 8px;
	}
}
