@use "sass:math";
@use '~@THC/styles/color';

.title {
	color: color.$primary-black;
	line-height: 3rem;
	margin-top: 0;
	font-size: 48px;
	font-weight: bold;
	margin-bottom: 8px;
	z-index: 1;
}

.text:global(.MuiTypography-root) {
	font-size: 16px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 1.5;
	z-index: 1;
}

.subTitle {
	color: color.$primary-black;
	font-weight: bold;
	line-height: 1.25;
	z-index: 1;
}

.loading {
	color: rgba(0, 0, 0, 0) !important;
}

.card:global(.MuiPaper-root) {
	overflow: hidden;
	padding: 24px;
	box-shadow: none;
	position: relative;
	svg {
		top: 50%;
		right: -31px;
		width: 112px;
		height: 112px;
		opacity: 0.2;
		position: absolute;
		transform: translate(0, -50%);
	}
	:global(.MuiCardContent-root) {
		padding: 0;
	}
}

.placeholderItem {
	&::before {
		animation: loadFrames 1s infinite cubic-bezier(0.4, 0, 0.2, 1);
		content: '';
		display: block;
		height: 100%;
		left: -150px;
		position: absolute;
		top: 0;
		width: 150px;
	}
	overflow: hidden;
	position: relative;
}

@keyframes loadFrames {
	from {
		left: -150px;
	}
	to {
		left: 100%;
	}
}
