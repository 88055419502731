@use "sass:math";
@use '~@THC/styles/color';
@use '~@THC/styles/typography';

.errorBoundary {
	& > div {
		text-align: center;
		width: 560px;
	}
	align-items: center;
	display: flex;
	justify-content: center;
	min-height: 100vh;

	.errorBoundaryLogo {
		& a {
			margin: auto;
		}
		& svg {
			background-color: color.$primary-yellow;
			height: 75px;
			padding: 10px;
			width: 300px;
		}
		display: flex;
		height: 100px;
	}

	.message {
		& > div {
			background-color: color.$secondary-green;
		}
		& > div > div {
			width: 100%;
		}
	}

	.textarea {
		height: 300px;
		max-width: 100%;
		width: 100%;
	}

	.title {
		@include typography.heading3;
		color: color.$secondary-red;
	}

	.button {
		> button {
			margin-bottom: 24px;
		}
	}
}
