@use "sass:math";
@use '~@THC/styles/color';

.logo {
	background-image: url(../assets/motorway-xmas-logo.svg);
	background-repeat: no-repeat;
	background-size: contain;
	height: 75px;
	width: 360px;
}
