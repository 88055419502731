@use "sass:math";
@use '~@THC/styles/color';

.avatar {
	height: 112px;
	width: 112px;
}

.h2:global.MuiTypography-root {
	line-height: 1.33;
	margin-bottom: 0;
}

.row {
	display: flex;
	margin-bottom: 32px;
	margin-top: 16px;
	height: 112px;
}

.title {
	margin-left: 24px;
	padding-top: 9px;
}

.divider {
	background-color: color.$light-grey-2;
	height: 2px;
	margin-bottom: 48px;
	margin-top: 46px;
}

.icon {
	opacity: 0.2;
	position: absolute;
	right: -31px;
	top: 50%;
	transform: translate(0, -50%);
}

.paperIcon {
	& > g {
		fill: color.$secondary-red;
		filter: none;
	}
	fill: color.$primary-white;
}

.paperIconCompany {
	fill: rgb(255 116 8);
}

.whiteIcon {
	& > path {
		fill: color.$primary-white;
	}
}

.yellowIcon {
	& > path {
		fill: rgba(255, 132, 0, 0.5);
	}
}
