@use "sass:math";
@use '~@THC/styles/color';
@use '~@THC/styles/modifiers';
@use '~@THC/styles/typography';

.dialog {
	:global(.MuiPaper-root) {
		border-radius: 16px;
		border: 2px solid #{modifiers.lightShade(color.$primary-black, 90%)};
		padding: 48px;
	}

	:global(.MuiDialogTitle-root) {
		@include typography.heading3;
		padding-bottom: 32px;
		margin: 0;
		text-align: center;
	}
}

.closeButton {
	z-index: 100;

	&:focus-visible {
		@include modifiers.focusRing();
	}

	&:global(.MuiIconButton-root) {
		color: #9e9e9e;
		position: absolute;
		right: 16px;
		top: 16px;
	}
}

.actions {
	&:global(.MuiDialogActions-root) {
		padding: 32px 0 0 0;
		justify-content: space-between;
		gap: 16px;
	}

	&.vertical {
		flex-direction: column;
	}
}
