@use "sass:math";
@use '~@THC/styles/color';

.nav {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.toolbarRoot {
	justify-content: flex-end;
	padding: 0 20px !important;
}

.root {
	&:global(.MuiAppBar-root) {
		background-color: color.$primary-yellow;
		font-size: 1.125rem;
		line-height: 1.6875rem;
		color: color.$primary-black;
		box-shadow: none;
		z-index: 1000;
		height: 64px;
	}
}
