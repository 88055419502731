@use "sass:math";
@use '~@THC/styles/color';

.wrapper {
	display: flex;
	min-height: 48px;
	flex-direction: column;
	justify-content: center;
	text-decoration: none;
	width: fit-content;
}

.vrm {
	cursor: pointer;
	display: flex;
	padding: 0 8px;
	max-width: 96px;
	min-width: 96px;
	text-align: center;
	align-items: center;
	font-weight: bold;
	white-space: nowrap;
	border-radius: 4px;
	text-transform: uppercase;
	justify-content: center;
	&ColorForVehicle {
		background-color: color.$primary-yellow;
		color: color.$primary-black;
	}


	&ColorForTradeVehicle {
		background-color: color.$semantic-info-25;
		color: color.$primary-white;
	}
}
