@use "sass:math";
.backdropReducedMotion {
	transition: none !important;
}

.backdropVisible {
	display: block;
	pointer-events: all !important;
	transition-delay: 0s !important;
	z-index: 999 !important;
}

.backdrop {
	pointer-events: none;
	transition-delay: 150ms !important;
	z-index: 999 !important;
}
