@use "sass:math";
@use '~@THC/styles/color';

.bigCard {
	& > div {
		height: 136px;
	}
}

.lowCard {
	& > div {
		height: 128px;
	}
}

.title:global(.MuiTypography-root) {
	font-size: 18px;
	margin-bottom: 10px;
}

@mixin style-card($textColor, $bigCardBg, $smallCardBg, $iconColor, $loadingBg) {
	:global(.MuiGrid-item) {
		&:first-child > :global(.MuiCard-root) {
			background-color: $bigCardBg;
			color: $textColor;
			:global(.MuiTypography-h5) {
				color: $textColor;
				font-size: 48px;
				line-height: 3rem;
				margin-bottom: 8px;
			}
		}
		:global(.MuiCard-root) {
			background-color: $smallCardBg;
			&::before {
				background: linear-gradient(to right, transparent 0%, $loadingBg 50%, transparent 100%);
			}
			:global(.MuiTypography-h5) {
				font-size: 2rem;
				margin-top: 0;
				font-weight: bold;
				line-height: 1.25;
				margin-bottom: 0;
			}
			svg {
				fill: $iconColor;
				path {
					fill: $iconColor;
				}
			}
		}
	}
}

.primary {
	@include style-card(
		color.$primary-white,
		color.$semantic-info-25,
		color.$tertiary-blue-1,
		color.$primary-white,
		color.$semantic-info-50
	);
}

.secondary {
	@include style-card(
		color.$primary-black,
		color.$primary-yellow,
		rgba(254, 242, 0, 0.3),
		color.$secondary-orange,
		color.$primary-yellow
	);
}
