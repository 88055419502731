@use "sass:math";
@use '~@THC/styles/color';

.contentWithSidebar {
	background-color: color.$light-grey-3;
	display: flex;
	flex-grow: 1;
	padding: 0;
}

.root {
	background-color: color.$primary-white;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	justify-content: space-between;
	min-height: calc(100vh - 192px);
	position: relative;
	z-index: 1;
}

.themeWrapper {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.content {
	display: flex;
	flex-direction: column;
	flex-grow: 2;
	padding: 0;
	width: 100%;
	background-color: transparent;
	&.list {
		background-color: color.$primary-white;
	}
}
