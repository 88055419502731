@use "sass:math";
@use '~@THC/styles/core';
@use '~@THC/styles/color';

.loading {
	left: -3px;
	position: relative;
}

.wrapper {
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	padding: core.$space * 6;
	position: absolute;
	width: 100%;
}

.title {
	color: color.$primary-black;
	font-size: 1.125rem;
	font-weight: bold;
	margin-bottom: 8px;
	line-height: core.$space * 3;
	margin-top: core.$space * 5;
}
