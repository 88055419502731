@use "sass:math";
@use '~@THC/styles/color';
@use '~@THC/styles/core';

.adornment {
	margin: 0;
	position: absolute;
	&:global(.MuiInputAdornment-root) {
		color: color.$primary-black;
	}
}

.fakePlaceholder {
	left: core.$space * 4;
	opacity: 1;
	pointer-events: none;
	position: absolute;
	transition: opacity 75ms ease;
	transition-delay: 75ms;
	white-space: nowrap;
}

.focused {
	transform: translateX(12px);
}

.inputBox {
	display: flex;
	font-size: 1rem;
	justify-content: flex-end;
	margin-right: core.$space * 4;
	position: relative;
	width: core.$space * 41;
}

.root {
	&:global(.MuiInput-root) {
		align-items: center;
		background-color: inherit;
		margin: 0;
		position: static;
		transform: translateX(0px);
		transition: transform 150ms ease;
		width: auto;
	}
}

.inputTypeSearch {
	&:global(.MuiInput-input) {
		&::-webkit-search-cancel-button {
			display: none;
		}

		&::placeholder {
			opacity: 0 !important;
		}

		&:focus {
			border-color: color.$primary-black;
			padding-left: core.$space * 4;
		}

		&:hover {
			border-color: color.$primary-black;
		}

		&:not(:placeholder-shown) {
			& + [data-fake-placeholder] {
				opacity: 0;
				transition: none;
			}
			border-color: color.$primary-black;
			padding-left: core.$space * 4;
		}

		&:placeholder-shown:not(:focus) {
			& + [data-fake-placeholder] {
				opacity: 0;
				transition: opacity 75ms ease;
				transition-delay: 0ms;
			}
			cursor: pointer;
			width: 0;
		}
		background: none;
		border: none;
		border-bottom: solid 2px transparent;
		border-radius: 0;
		box-sizing: content-box;
		contain: content;
		height: core.$space * 3;
		padding: 11px 0 11px core.$space * 3;
		transition: width 150ms ease, border-color 150ms ease-in-out;
		width: core.$space * 37;
		will-change: width;
	}
}
