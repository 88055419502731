@use "sass:math";
.wrapper {
	flex-grow: 1;
	height: 48px;
}

.link {
	align-items: center;
	display: inline-flex;
	text-decoration: none;
}

.logo {
	background-image: url(assets/MotorwayLogoBlack.svg);
	background-repeat: no-repeat;
	background-size: contain;
	height: 48px;
	margin-right: 16px;
	width: 160px;
}

.xlogo {
	background-image: url(../ChristmasTheme/assets/motorway-xmas-logo.svg);
	background-repeat: no-repeat;
	background-size: contain;
	height: 32px;
	margin-right: 16px;
	width: 160px;
}

.logoText {
	font-size: 1.125rem !important;
	line-height: 1.6875rem !important;
}
