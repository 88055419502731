@use "sass:math";
@use '~@THC/styles/core';

.root {
	display: flex;
	overflow: hidden;
	height: inherit;
	flex-direction: column;
	min-height: 100vh;
	max-height: 100vh;

	.container {
		display: flex;
		flex: 1;
		overflow: hidden;
		height: inherit;
	}
}
