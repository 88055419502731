@use "sass:math";
@use '~@THC/styles/color';
@use '~@THC/styles/core';

.avatar {
	height: core.$space * 6;
	margin: 0;
	width: core.$space * 6;
}

.cellWrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: core.$space * 6;
}

.fallbackResultsContainer {
	align-items: center;
	display: flex;
	flex-direction: column;
	height: core.$space * 27;
	padding: core.$space * 6;
}

.make {
	min-width: core.$space * 12;
}

.messageBox {
	& + table {
		border-top: solid 2px color.$light-grey-3;
	}
	& > h4 {
		margin: 0;
	}
	padding: core.$space * 3;
}

.mobile {
	& a[href^='tel:'] {
		text-decoration: none;
		width: fit-content;
	}
}

.noResultsText {
	margin: 0;
}

.noResultsTitle {
	margin-bottom: core.$space;
	margin-top: core.$space * 4;
}

.resultsBox {
	& > div {
		position: unset;
		box-shadow: none;
	}
	background: color.$primary-white;
	border-radius: 0 0 core.$border-radius core.$border-radius;
	contain: content;
	height: calc(100vh - 16px);
	max-height: var(--universal-search-results-max-height);
	overflow: auto;
	pointer-events: all;
	position: absolute;
	top: 0;
	transform: translateY(0);
	transition: max-height 150ms ease, transform 150ms ease;
	width: 100%;
	will-change: max-height, transform;
	:global(.MuiCardContent-root) {
		padding: 0;
	}
}

.resultsBoxEmpty {
	min-height: core.$space * 27;
}

.resultsBoxHidden {
	transform: translateY(-100%);
}

.resultsBoxReducedMotion {
	transition: none;
}

.resultsBoxSlideDelay {
	transition-delay: 0ms, 150ms;
}

.resultsContainer {
	height: calc(100vh - 96px);
	left: 50%;
	min-width: core.$space * 101;
	overflow: hidden;
	pointer-events: none;

	// standard props
	position: absolute;
	top: 56px;
	transform: translateX(-50%);
	width: 100%;
	z-index: 1000;
}

.resultsWrapper {
	transition: opacity 75ms ease;
	will-change: opacity;
}

.resultsWrapperIn {
	opacity: 1;
}

.resultsWrapperOut {
	opacity: 0;
}

.searchResultRow {
	& td {
		& > a {
			color: currentColor;
			display: block;
			padding: core.$space * 2 12px !important;
			text-decoration: none;
			:global(.MuiAvatar-root) {
				width: 48px;
				height: 48px;
				margin-right: 8px;
			}
		}
		&:first-child > a {
			padding-left: core.$space * 3 !important;
		}
		&:last-child > a {
			padding-right: core.$space * 3 !important;
		}
		border-bottom: solid 2px color.$light-grey-3 !important;
		padding: 0 !important;
	}
	&:focus {
		outline: solid 2px color.$light-grey-3;
		outline-offset: -2px;
	}
	&:hover {
		background-color: color.$light-grey-3;
	}
}

.sentence {
	& mark {
		background: none;
		color: currentColor;
		font-weight: bold;
	}
}

.singleLine {
	white-space: nowrap;
}

.table {
	margin: 0;
}

.vrm {
	& span {
		font-family: 'UK Number Plate', Arial, Helvetica, sans-serif;
	}
}
.vrmOnResult {
	& span {
		font-family: NewTransport, Arial, Helvetica, sans-serif;
		font-size: 1rem;
		font-weight: bold;
		white-space: nowrap;
	}
}
