@use "sass:math";
@use '~@THC/styles/color';
@use '~@THC/styles/typography';
@use '~@THC/styles/modifiers';

.wrapper {
	display: grid !important;
	grid-template-columns: 40% 60%;
	background-color: color.$primary-yellow;
}

.loginWrapper {
	width: 100%;
	height: 100%;
}

.whiteBackground{
	position: fixed;
	width: 60%;
	height: 100%;
	z-index: -1;

	// Chevron Height
	svg {
		height: 100%;
	}
}

.login {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	height: 100%;
	width: 100%;
	padding-left: 100px;

	.joke {
		font-size: 24px;
		line-height: 32px;
		margin-bottom: 32px;
		color: #{modifiers.lightShade(color.$primary-black, 25%)};
	}
}

.yellowBackgound {
	width: 100%;
	height: 100%;
	background-image: url('../assets/christmas-celebration.svg');
	background-color: color.$primary-yellow;
	z-index: -2;
	background-position: -200px -200px;
	background-size: 1800px;

	@media only screen and (min-width: 1800px) {
		background-size: 2000px;
	}
}

.logo {
	display: flex;
	gap: 24px;
	margin-bottom: 24px;
	align-items: center;
}

.title {
	font-size: 48px;
	font-weight: 300;
	line-height: 56px;
	letter-spacing: 0px;
	text-align: left;
	padding: 0;
	margin: 0;
	color: #{modifiers.lightShade(color.$primary-black, 25%)};
}

.buttonLogin {
	width: 360px;
}

.buttonLabel {
	display: flex;
	align-items: center;
	gap: 8px;

	// One Login Logo Height
	svg {
		height: 25px;
	}
}

.oneLoginLogo {
	margin-top: 7px;
}
